import React, { useCallback, useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  makeStyles,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import { clsx } from 'clsx';
import {
  FastField,
  Field,
  FieldProps,
  Form,
  Formik,
  getIn,
  useFormikContext,
} from 'formik';
import _ from 'lodash';
import * as Yup from 'yup';

import { ReactComponent as Cheveron } from '@assets/DownCheveron.svg';

import { FormikNumericDateField } from '@components/FormikNumericDateField/FormikNumericDateField';
import {
  FormikErrorSnackbar,
  NumberFormatPhone,
  SinglePanelLayout,
} from '@components/index';

import { useEncryptedLocalStorage, usePrevious } from '@hooks';
import useClinicConfig from '@hooks/useClinicConfig';
import { useFeatureFlags } from '@hooks/useFeatureFlags';

import MoreInfoIcon from '@screens/CheckIn/icons/MoreInfo.svg';

import { getLanguage, useExtendedTranslation } from '@services/i18nService';

import {
  DEFAULT_INIT_PATRON_VALUE as DEFAULT_USER_DATA,
  LOCAL_STORAGE_PROPERTIES,
  VCDS_LOCAL_STORAGE_PROPERTIES,
} from '@util/defaultValues';
import { PHONE_REG_EXP } from '@util/phoneValid';
import {
  areAllValuesTruthy,
  USER_ADDRESS_ENTRY_REGEX,
  USER_ENTRY_REGEX,
} from '@util/util';

import '@screens/CheckIn/ScreenCheckIn/ScreenCheckIn.scss';

// TODO: Move to better location and update list from The Royal
export const PRONOUNS = {
  en: ['Undisclosed', 'Unspecified', 'He/Him', 'She/Her', 'They/Them'],
  fr: ['Non divulgué', 'Non précisé', 'Il/Lui', 'Elle/Lui', 'Iel/Eille'],
};
export const DISPLAYABLE_PRONOUNS_START_INDEX = 2; // Start Index in above list, for pronouns that should appear in name display

const useStyles = makeStyles({
  select: {
    width: '100%',
    '& .MuiSelect-root': {
      // padding to stay consistent with other "dense" inputs
      paddingTop: '10.5px',
      paddingBottom: '10.5px',
    },
  },
  selectLabel: {
    marginBottom: '0.5rem !important',
  },
  indent: {
    marginLeft: '0.5rem',
  },
  rememberMe: {
    marginTop: '10px',
    marginBottom: '14px',
  },
  helperText: {
    fontSize: '0.875rem',
  },
});

function CheckInForm({
  onRegion,
  pronouns,
}: {
  onRegion?: Function;
  pronouns: string[];
}): JSX.Element {
  const classes = useStyles();
  const t = useExtendedTranslation();
  const clinicConfig = useClinicConfig();
  const { REACT_APP_DISABLE_REQUIRED_CURRENT_LOCATION, REACT_APP_IS_VCDS } =
    useFeatureFlags();

  const previousPronouns = usePrevious<string[] | undefined>(pronouns);

  const { isSubmitting, isValidating, values, setFieldValue, submitForm } =
    useFormikContext();

  // Reset 'pronoun' value when the set of options update (language change)
  useEffect(() => {
    if (previousPronouns?.length) {
      setFieldValue('pronoun', '');
    }
  }, [pronouns, previousPronouns, setFieldValue]);

  return (
    <Form className="formLayout">
      <FormikErrorSnackbar />
      <SinglePanelLayout
        ariaLive="off"
        footer={
          <Grid container justifyContent="center">
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                disabled={
                  isSubmitting ||
                  isValidating ||
                  areAllValuesTruthy(
                    _.pick(values, [
                      'firstName',
                      'lastName',
                      'phoneNumber',
                      ...(REACT_APP_IS_VCDS ? ['dob'] : ['email']),
                      REACT_APP_DISABLE_REQUIRED_CURRENT_LOCATION
                        ? ''
                        : 'currentAddress',
                      clinicConfig.enableMapScreen && !REACT_APP_IS_VCDS
                        ? 'inRegion'
                        : '',
                    ])
                  )
                }
                onClick={submitForm}
              >
                {t('checkin.continue.button')}
              </Button>
            </Grid>
          </Grid>
        }
        logo={clinicConfig.logoName}
      >
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography className="description">
              {t('checkin.description1')}
            </Typography>
            {!REACT_APP_IS_VCDS && (
              <Typography className="description">
                {t('checkin.description2')}
              </Typography>
            )}
          </Grid>

          <Grid item>
            <FormControl>
              <FormLabel required>{t('checkin.firstName')}</FormLabel>
              <FastField name="firstName">
                {({ form, field }: FieldProps) => (
                  <TextField
                    fullWidth
                    error={
                      getIn(form.errors, field.name) &&
                      getIn(form.touched, field.name)
                    }
                    variant="outlined"
                    margin="dense"
                    inputProps={{ maxLength: 128 }}
                    {...field}
                  />
                )}
              </FastField>
            </FormControl>
          </Grid>

          <Grid item>
            <FormControl>
              <FormLabel required>{t('checkin.lastName')}</FormLabel>
              <FastField name="lastName">
                {({ form, field }: FieldProps) => (
                  <TextField
                    fullWidth
                    error={
                      getIn(form.errors, field.name) &&
                      getIn(form.touched, field.name)
                    }
                    variant="outlined"
                    margin="dense"
                    inputProps={{ maxLength: 128 }}
                    {...field}
                  />
                )}
              </FastField>
            </FormControl>
          </Grid>

          {REACT_APP_IS_VCDS && (
            <>
              <Grid item>
                <FormControl>
                  <Typography className={classes.selectLabel}>
                    {t('checkin.pronoun')}
                  </Typography>
                  <FastField name="pronoun" key={pronouns.join()}>
                    {({ field, form }: FieldProps) => {
                      return (
                        <Select
                          {...field}
                          onChange={(
                            event: React.ChangeEvent<{ value: unknown }>
                          ) => {
                            form.setFieldValue(
                              field.name,
                              event.target.value as string
                            );
                          }}
                          defaultValue={field.value}
                          variant="outlined"
                          IconComponent={Cheveron}
                          className={classes.select}
                          displayEmpty
                        >
                          {pronouns.map((data: string) => {
                            return (
                              <MenuItem key={data} value={data}>
                                {data}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      );
                    }}
                  </FastField>
                </FormControl>
              </Grid>

              <Grid item>
                <FormControl>
                  <FormLabel required>{t('checkin.dob')}</FormLabel>
                  <FastField name="dob">
                    {({ form, field }: FieldProps) => (
                      <FormikNumericDateField
                        id="dob"
                        error={
                          getIn(form.errors, field.name) &&
                          getIn(form.touched, field.name)
                        }
                        required
                        {...field}
                      />
                    )}
                  </FastField>
                </FormControl>
              </Grid>
            </>
          )}

          {!REACT_APP_IS_VCDS && (
            <>
              <Grid item>
                <FormControl>
                  <FormLabel required>{t('checkin.phone')}</FormLabel>
                  <FastField name="phoneNumber">
                    {({ form, field }: FieldProps) => (
                      <TextField
                        fullWidth
                        error={
                          getIn(form.errors, field.name) &&
                          getIn(form.touched, field.name)
                        }
                        variant="outlined"
                        margin="dense"
                        InputProps={{
                          inputComponent: NumberFormatPhone,
                        }}
                        inputProps={{ maxLength: 128 }}
                        {...field}
                        onChange={(val) =>
                          form.setFieldValue(field.name, val.target.value)
                        }
                      />
                    )}
                  </FastField>
                </FormControl>

                <FormControl className={clsx(classes.indent)}>
                  <FormControlLabel
                    control={
                      <FastField name="allowSMS">
                        {({ field, form }: FieldProps) => (
                          <Switch
                            checked={field.value}
                            onChange={(
                              _event: React.ChangeEvent<HTMLInputElement>,
                              checked: boolean
                            ) => form.setFieldValue(field.name, checked)}
                            color="primary"
                            size="small"
                            type="checkbox"
                          />
                        )}
                      </FastField>
                    }
                    label={<Typography>{t('checkin.allowSMS')}</Typography>}
                  />
                </FormControl>
              </Grid>

              <Grid item>
                <FormControl>
                  <FormLabel required>{t('checkin.email')}</FormLabel>
                  <FastField name="email">
                    {({ form, field }: FieldProps) => (
                      <TextField
                        fullWidth
                        type="email"
                        error={
                          getIn(form.errors, field.name) &&
                          getIn(form.touched, field.name)
                        }
                        variant="outlined"
                        margin="dense"
                        inputProps={{ maxLength: 128 }}
                        {...field}
                      />
                    )}
                  </FastField>
                </FormControl>
              </Grid>
            </>
          )}

          {clinicConfig.enableMapScreen && !REACT_APP_IS_VCDS && (
            <Grid item>
              <FormGroup className={clsx(classes.indent)}>
                <FormControlLabel
                  control={
                    <FastField name="inRegion">
                      {({ field, form }: FieldProps) => (
                        <Switch
                          checked={field.value}
                          onChange={(
                            _event: React.ChangeEvent<HTMLInputElement>,
                            checked: boolean
                          ) => form.setFieldValue(field.name, checked)}
                          color="primary"
                          size="small"
                          type="checkbox"
                        />
                      )}
                    </FastField>
                  }
                  label={
                    <Grid
                      container
                      alignItems="center"
                      wrap="nowrap"
                      spacing={1}
                    >
                      <Grid item>
                        <Typography variant="body2" color="primary">
                          {t('checkin.region', { ...clinicConfig })}
                        </Typography>
                      </Grid>
                      {clinicConfig.enableMoreRegionInfoScreen && (
                        <Grid item>
                          <IconButton
                            onClick={() => onRegion && onRegion(values)}
                            size="small"
                          >
                            <img src={MoreInfoIcon} alt={t('checkin.alt')} />
                          </IconButton>
                        </Grid>
                      )}
                    </Grid>
                  }
                />
              </FormGroup>
            </Grid>
          )}

          <Grid item>
            <FormControl>
              <FormLabel
                required={!REACT_APP_DISABLE_REQUIRED_CURRENT_LOCATION}
              >
                {t('checkin.address')}
                {REACT_APP_DISABLE_REQUIRED_CURRENT_LOCATION && (
                  <>
                    <br />
                    <Typography className={clsx(classes.helperText)}>
                      {t('checkin.address.why')}
                    </Typography>
                  </>
                )}
              </FormLabel>
              <Field name="currentAddress">
                {({ form, field }: FieldProps) => (
                  <TextField
                    placeholder={t('provideInfo.homeAddress.placeholder')}
                    fullWidth
                    error={
                      getIn(form.errors, field.name) &&
                      getIn(form.touched, field.name)
                    }
                    variant="outlined"
                    multiline
                    minRows={3}
                    margin="dense"
                    inputProps={{ maxLength: 128 }}
                    {...field}
                  />
                )}
              </Field>
            </FormControl>
            {REACT_APP_IS_VCDS && (
              <FormHelperText>{t('checkin.address.helper')}</FormHelperText>
            )}
          </Grid>

          {REACT_APP_IS_VCDS && (
            <Grid item>
              <FormControl>
                <FormLabel required>{t('checkin.phone')}</FormLabel>
                <FastField name="phoneNumber">
                  {({ form, field }: FieldProps) => (
                    <TextField
                      fullWidth
                      error={
                        getIn(form.errors, field.name) &&
                        getIn(form.touched, field.name)
                      }
                      variant="outlined"
                      margin="dense"
                      InputProps={{
                        inputComponent: NumberFormatPhone,
                      }}
                      inputProps={{ maxLength: 128 }}
                      {...field}
                      onChange={(val) =>
                        form.setFieldValue(field.name, val.target.value)
                      }
                    />
                  )}
                </FastField>
              </FormControl>

              <FormControl className={clsx(classes.indent)}>
                <FormControlLabel
                  control={
                    <FastField name="allowSMS">
                      {({ field, form }: FieldProps) => (
                        <Switch
                          checked={field.value}
                          onChange={(
                            _event: React.ChangeEvent<HTMLInputElement>,
                            checked: boolean
                          ) => form.setFieldValue(field.name, checked)}
                          color="primary"
                          size="small"
                          type="checkbox"
                        />
                      )}
                    </FastField>
                  }
                  label={<Typography>{t('checkin.allowSMS')}</Typography>}
                />
              </FormControl>
            </Grid>
          )}

          <Grid item>
            <FormGroup className={clsx(classes.indent, classes.rememberMe)}>
              <FormControlLabel
                control={
                  <FastField name="rememberMe">
                    {({ field, form }: FieldProps) => (
                      <Switch
                        checked={field.value}
                        onChange={(
                          _event: React.ChangeEvent<HTMLInputElement>,
                          checked: boolean
                        ) => form.setFieldValue(field.name, checked)}
                        color="primary"
                        size="small"
                        type="checkbox"
                      />
                    )}
                  </FastField>
                }
                label={<Typography>{t('checkin.rememberMe')}</Typography>}
              />
            </FormGroup>
          </Grid>
        </Grid>
      </SinglePanelLayout>
    </Form>
  );
}

function ScreenCheckIn({
  onNext,
  onRegion,
}: {
  onNext: Function;
  onRegion?: Function;
}): JSX.Element {
  const t = useExtendedTranslation();
  const clinicConfig = useClinicConfig();
  const { REACT_APP_DISABLE_REQUIRED_CURRENT_LOCATION, REACT_APP_IS_VCDS } =
    useFeatureFlags();

  const lang = getLanguage();
  const CURRENT_PRONOUNS = PRONOUNS[lang] || PRONOUNS.en;

  const [rememberedData, setRememberedData] = useEncryptedLocalStorage(
    'surveyData',
    DEFAULT_USER_DATA
  );

  const initialValues = REACT_APP_IS_VCDS
    ? {
        ...rememberedData,
        pronoun: _.includes(CURRENT_PRONOUNS, rememberedData.pronoun)
          ? rememberedData.pronoun
          : '',
        dob:
          rememberedData.dobYear &&
          rememberedData.dobDay &&
          rememberedData.dobMonth
            ? `${rememberedData.dobYear}-${rememberedData.dobMonth}-${rememberedData.dobDay}`
            : '',
      }
    : rememberedData;

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .matches(USER_ENTRY_REGEX, t('checkin.firstName.error'))
      .max(128, t('checkin.firstName.error'))
      .required(t('checkin.firstName.error')),
    lastName: Yup.string()
      .matches(USER_ENTRY_REGEX, t('checkin.lastName.error'))
      .max(128, t('checkin.lastName.error'))
      .required(t('checkin.lastName.error')),
    currentAddress: REACT_APP_DISABLE_REQUIRED_CURRENT_LOCATION
      ? Yup.string()
      : Yup.string()
          .matches(USER_ADDRESS_ENTRY_REGEX, t('checkin.address.error'))
          .max(128, t('checkin.address.error'))
          .required(t('checkin.address.error')),
    ...(REACT_APP_IS_VCDS
      ? {
          pronoun: Yup.string(),
          dob: Yup.date()
            .required(t('checkin.dob.error'))
            .max(new Date(), t('checkin.dob.error')),
        }
      : {
          email: Yup.string()
            .email(t('checkin.email.error'))
            .matches(USER_ENTRY_REGEX, t('checkin.email.error'))
            .required(t('checkin.email.error')),
          phoneNumber: Yup.string()
            .matches(PHONE_REG_EXP, t('checkin.phone.error'))
            .matches(USER_ENTRY_REGEX, t('checkin.phone.error'))
            .min(10, t('checkin.phone.error'))
            .required(t('checkin.phone.error')),
          inRegion: Yup.boolean()
            .oneOf([true, false], t('checkin.inRegion.error'))
            .required(t('checkin.inRegion.error')),
        }),
    phoneNumber: Yup.string()
      .matches(PHONE_REG_EXP, t('checkin.phone.error'))
      .matches(USER_ENTRY_REGEX, t('checkin.phone.error'))
      .min(10, t('checkin.phone.error'))
      .required(t('checkin.phone.error')),
  });

  const handleSubmit = useCallback(
    (values: any) => {
      if (values.rememberMe) {
        setRememberedData({
          ...rememberedData,
          ..._.pick(
            values,
            REACT_APP_IS_VCDS
              ? VCDS_LOCAL_STORAGE_PROPERTIES
              : LOCAL_STORAGE_PROPERTIES
          ),
        });
      }

      if (REACT_APP_IS_VCDS) {
        const [dobYear, dobMonth, dobDay] = values!.dob!.split('-'); // Enforce non-null assertion operator since we are validating the date in the Yup schema

        return onNext({
          ...values,
          dobYear,
          dobMonth,
          dobDay,
        });
      }
      return onNext({
        ...values,
        inRegion: clinicConfig.enableMapScreen ? values.inRegion : true,
      });
    },
    [
      REACT_APP_IS_VCDS,
      clinicConfig.enableMapScreen,
      onNext,
      rememberedData,
      setRememberedData,
    ]
  );

  return (
    <Box display="flex" flex={1} className="screen-check-in">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {() => <CheckInForm onRegion={onRegion} pronouns={CURRENT_PRONOUNS} />}
      </Formik>
    </Box>
  );
}
export default ScreenCheckIn;
